import React, {useState} from "react"
import {Link} from "gatsby"
import {InstantSearch, connectStateResults, connectSearchBox, connectHits, Highlight, Snippet} from 'react-instantsearch-dom'
import algoliasearch from "algoliasearch/lite"
import styled from 'styled-components'

import Special from '../components/special'

import {addSpace, modularScale, breakpoints} from '../utils/helpers'

const SearchIcon = () => (
	<svg style={{
		width: `${modularScale(5)}`, 
		position: 'relative', 
		top: '4px', 
		fill: 'var(--base-color)',
	}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 
		44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 
		208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 
		9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 
		0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 
		0 70.7-57.2 128-128 128z"/>
	</svg>
)

const Home = () => (
	<svg style={{
		width: `${modularScale(3)}`,
		position: 'absolute',
		margin: '1rem',
		fill: 'var(--base-color)',
	}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
		<path d="M570.69 236.28L512 184.45V48a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v51.69L314.75 10.31a39.85 39.85 
		0 0 0-53.45 0l-256 226a16 16 0 0 0-1.21 22.6L25.5 282.7a16 16 0 0 0 22.6 1.21L277.42 81.63a16 16 0 0 1 21.17 0L527.91 
		283.9a16 16 0 0 0 22.6-1.21l21.4-23.82a16 16 0 0 0-1.22-22.59zM288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 
		16 0 0 0 16 16h128a16 16 0 0 0 16-16V368a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 
		16-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2z"/>
	</svg>
)

const ResultBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	border-radius: 4px;
	margin: 1rem 2rem;

	div {
		padding: 1rem;
		border-bottom: 1px solid black;
		width: 50%;

		${breakpoints.lg} {
			width: 75%;
		}

		${breakpoints.md} {
			width: 100%;
		}

		&:hover {
			background: lightgray;
		}
	}

	p {
		margin: 0;
	}

	h4 {
		margin-bottom: 0
	}

	h6 {
		margin-bottom: .5rem;
	}
`

const SearchBox = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;

	input {
		font-size: ${modularScale(5)};

		${breakpoints.md} {
			font-size: ${modularScale(4)};
		}

		${breakpoints.sm} {
			font-size: ${modularScale(3)};
		}
	}
`

const StyledInput = styled.div`
	margin: 0 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid var(--base-color);

	${breakpoints.lg} {
		margin: 0 10%;
	}

	${breakpoints.md} {
		margin: 0 3%;
	}
`

const SearchInput = connectSearchBox(({ currentRefinement, refine }) => (
	<StyledInput>
		<SearchIcon />
		<input type="text" 
			placeholder="Search" 
			aria-label="Search"
			value={currentRefinement} 
			onChange={e => refine(e.target.value)}
			style={{border: 'none', outline: 'none', width: '100%'}}
			autoFocus
		/>
	</StyledInput>
))

const HitComp = connectHits(({ hits }) => (
	<ResultBox>
		{hits.map(hit => (
			<div key={hit.objectID}><Link to={hit.url}>
				<h4><Highlight attribute="title" hit={hit} tagName="mark" /></h4>
				<h6><span style={{color: 'black', fontWeight: 400,}}>in</span> {addSpace(hit.kb)}</h6>
				<p><Snippet attribute="content" hit={hit} tagName="mark" /></p>
			</Link></div>
		))}
	</ResultBox>
))

const Results = connectStateResults(
	({ searchState: state, searchResults: res}) => {
		if(state.hasOwnProperty('query')){
			if(state.query !== ''){
				if(res && res.nbHits > 0){
					return(<HitComp />)
				}
				return(<ResultBox><p style={{margin: '1rem'}}>No results found for <span style={{color: '#15537f' }}>{state.query}</span></p></ResultBox>)
			}
		}
		return(<ResultBox></ResultBox>)
	}
)

export default function Search({location}){
	const[query, setQuery] = useState(
		(location.state === null) || 
		(location.state === undefined) || 
		(location.state.query === undefined) ? '' : location.state.query)
	return (
		<>
		<Link to={'/'}><Home /></Link>
		<InstantSearch
			searchClient={algoliasearch( process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY )}
			searchState={{query: query}}
			indexName={process.env.GATSBY_INDEX}
			onSearchStateChange={({query}) => setQuery(query)}
			root={{ Root: SearchBox }}
		>
			<SearchInput />
			<Results />
		</InstantSearch>
		<Special />
		</>
	)
}